@import 'mixins'

.hide
  opacity: 0
.opacity-50
  opacity: .50

.hide-1150
  @media (max-width: 1150px)
    display: none

.xs-hide,
.tablet-hide
  @media (max-width: $tablet-view)
    display: none !important

.mobile-hide
  @media (max-width: $mobile-view)
    display: none !important

.hide-before-1000
  @media (min-width: $tablet-view+1) and (max-width: 1000px)
    display: none !important

.xs-show
  display: none !important
  @media (max-width: $tablet-view)
    display: flex !important

.event-none,
.events-none
  pointer-events: none

.display-none
  display: none
.display-none-i
  display: none !important
.display-flex-i
  display: flex !important

.archive-m-hide
  display: flex
  @media (max-width: 1000px)
    display: none
.archive-m-show
  display: none
  @media (max-width: 1000px)
    display: flex

.overflow,
.overflow-hidden
  overflow: hidden
.overflow
  &-x
    overflow-x: hidden
  &-y
    overflow-y: hidden
  &-auto
    overflow: auto
  &-visible
    overflow: visible
    &-i
      overflow: visible !important

.circle-xxs
  width: 4px
  height: 4px
  border-radius: 50%
.circle-xs
  width: 8px
  height: 8px
  border-radius: 50%

.circle-10
  width: 10px
  height: 10px
  border-radius: 50%

.circle-50
  width: 50px
  height: 50px
  border-radius: 50%

.height
  &-25
    height: 25px
  &-30
    height: 30px
  &-35
    height: 35px
  &-36
    height: 36px
  &-40
    height: 40px
  &-full
    height: 100%
  &-50
    height: 50px
  &-55
    height: 55px
  &-100-dhv
    height: 100dvh
  &-100
    height: 100px
    &-max
      max-height: 100px
  &-360-max
    max-height: 360px
  &-mobile-auto-i
    @media (max-width: $mobile-view)
      height: auto !important
.width
  &-tablet-100
    @media (max-width: $tablet-view)
       width: 100px !important
  &-20
    width: 20px
  &-30
    width: 30px
  &-40
    width: 40px
  &-50
    width: 50px
  &-75
    width: 75px
  &-80
    width: 80px
  &-100
    width: 100px
  &-110
    width: 110px
  &-120
    width: 120px
  &-140
    width: 140px
  &-150
    width: 150px
  &-175
    width: 175px
  &-180
    width: 180px
    &-max
      max-width: 360px
  &-225
    width: 225px
  &-250
    width: 250px
  &-300
    width: 300px
  &-250-min
    min-width: 250px
  &-360-max
    max-width: 360px
  &-500-max
    max-width: 500px
  &-max-content
    width: max-content
  &-max
    &-perc-60
      max-width: 60%
  &-full-max
    max-width: 100%
    &-mobile
      @media (max-width: $mobile-view)
        max-width: 100% !important
  &-full
    width: 100%
    &-mobile
      @media (max-width: $mobile-view)
        width: 100%
    &-tablet
      @media (max-width: $tablet-view)
        width: 100%

.rotate
  transform: rotate(180deg)

.pointer
  cursor: pointer

.hover-active
  cursor: pointer
  &:hover
    opacity: 0.75

.hover-active-bg
  cursor: pointer
  &:hover
    background-color: $table-bg-grey

.hover-show
  &:not(.hover-show:hover)
    .child
      display: none

.hover-active-blue
  cursor: pointer
  &:hover
    color: $tempton-blue
    border-color: $tempton-blue
    & > *
      border-color: $tempton-blue

.disc-list-item
  list-style: disc

.z-1
  z-index: 1
.z-2
  z-index: 2
.z-3
  z-index: 3

.shadow
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05)

.hide-scrollbar
  &::-webkit-scrollbar
    display: none

.counter
  height: 16px
  min-width: 25px
  padding: 0 4px
  border-radius: 2px
  &.green
    background-color: $bright-green
  &.orange
    background-color: $bright-orange
  &.red
    background-color: $bright-red
  &.purple
    background-color: $bright-purple
  &.blue
    background-color: $bright-blue
  &.mini
    position: absolute
    top: 7px
    right: 15px
    height: 15px
    min-width: 20px

.skeleton
  display: inline-block
  background-color: #e0e0e0!important
  border-color: transparent!important
  color: transparent!important
  &-animation
    background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%)
    background-size: 200% 100%
    animation: skeleton-loading 3s infinite

  @keyframes skeleton-loading
    0%
      background-position: 200% 0
    100%
      background-position: -200% 0

.skeleton-img
  width: 400px !important
  height: 600px !important
  @media (max-width: $mobile-view)
    width: 85vw !important
    height: 490px !important
