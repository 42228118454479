@import 'colors'

html, body
  min-height: 100dvh
  height: 100dvh
  font-family: 'Poppins', sans-serif
  background-color: $white
  overflow: hidden

html
  position: relative
body
  margin: 0
  overflow-x: hidden
  color: $secondary-grey
  font-weight: 300

ul
  margin: 0
  padding: 0
  list-style: none

.page-wrapper
  width: 100dvw
  overflow-y: auto
  overflow-x: hidden
  position: relative
  background-color: $main-bg-grey

.content-box
  height: 100%
  display: flex
  flex-direction: column
  background-color: $white
  border-radius: $border-radius-default
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05)

  margin-top: $size-l
  padding: $size-l
  gap: $size-l
  @media (max-width: $laptop-view)
    margin-top: $size-m
    padding: $size-m
    gap: $size-m
  @media (max-width: $tablet-view)
    margin-top: $size-s
    padding: $size-s
    gap: $size-s
  @media (max-width: $mobile-view)
    gap: 10px
    margin-top: 5px
    padding: 10px 7.5px

.container-fluid
  width: 100%
  padding: 0 $size-l $size-l $size-l
  @media (max-width: $laptop-view)
    padding: 0 $size-m $size-m $size-m
  @media (max-width: $tablet-view)
    padding: 0 $size-s $size-s $size-s
  @media (max-width: $mobile-view)
    padding: 0 7.5px 10px 7.5px !important

.externals-select-popup
  .custom-popup-wrapper
    width: 500px

.dynamic-modal-popup
  position: fixed
  z-index: 20
  left: 0
  right: 0
  top: 0
  bottom: 0
  background-color: rgba(0, 0, 0, 0.25)
  .dynamic-modal-content
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    background-color: #fff
    border-radius: 6px
    display: flex
    flex-direction: column
    width: calc( 100% - 30px)
    max-height: calc(100% - 40px)
    padding: 15px
